<template lang="pug">
  .days-count-toggler(
    v-click-outside="() => { toggled = false }"
    @click="toggleDropdown")
    span {{ 'pages.storage.weight_per_day' | translate }} #[br] {{ items[selected].label | translate }} [{{ 'units.kg' | translate }}]
    .toggler
      v-chevron(:value="toggled")
    .days-count-toggler__options(
      v-if="toggled")
      v-card(
        elevated
        overflow-hidden)
        .options__item(
          v-for="(item, index) in items"
          @click.stop="onItemClick(index)")
          | {{ item.label | translate }}

</template>

<script>
export default {
  name: 'DaysCountToggler',

  props: {
    value: {
      type: String,
      default: 'today'
    }
  },

  data: () => ({
    items: [
      { label: 'switch_days.today', value: 'today' },
      { label: 'switch_days.yesterday', value: 'yesterday' },
      { label: 'switch_days.week', value: 'week' },
      { label: 'switch_days.two_weeks', value: 'two_weeks' },
      { label: 'switch_days.month', value: 'month' }
    ],
    selected: 0,
    toggled: false
  }),

  methods: {
    toggleDropdown () {
      this.toggled = !this.toggled
    },

    onItemClick (index) {
      this.selected = index
      this.toggled = false
      this.$emit('input', this.items[index].value)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .days-count-toggler {
    cursor: pointer;
    position: relative;
    width: 140px;
    z-index: 10;

    span {
      margin-right: 1rem;
    }

    .toggler {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    .days-count-toggler__options {
      width: 100%;
      position: absolute;
      top: 40px;

      .options__item {
        cursor: pointer;
        user-select: none;
        color: $color-black;
        padding: 8px 16px;

        &:hover {
          background-color: $table-row-bg;
        }
      }
    }
  }
</style>
